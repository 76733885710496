.header-content-main {
    background-color: #12032e;
    width: 100%;
    height: 75px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    .header-items-list-holder {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    li {
        font-family: "RalewayRegular";
        font-size: 17px;
        display: inline-block;
        margin-right: 70px;
    }

    a {
        text-decoration: none;
        color: #fff;
        cursor: pointer;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .header-items-list-center img {
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .header-items-list-left {
        flex: 1;
    }

    .header-items-list-center {
        width: 100px;
        height: 75px;
    }

    .header-items-list-right {
        flex: 1;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .header-items-list-holder::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 31px solid transparent;
        border-right: 31px solid transparent;
        border-bottom: 19px solid #12032f;
        top: 75px;
        left: 50%;
        transform: translate(-50%, 0) rotate(180deg);
    }

    .active-language {
        font-size: 14px;
        color: #d900a1;
    }

    .header-items-list-languages {
        display: flex;
        flex-direction: row;
        font-family: RalewayBold;
        font-size: 14px;
        justify-content: center;
        align-content: center;
    }

    .language-item-header-sr {
        margin-right: 10px;
        border-right: 1px solid #d900a1;
        padding-right: 10px;
        margin-top: 3px;
    }

    .language-item-header-en {
        margin-top: 3px;
    }

    .language-item-header {
        cursor: pointer;
    }
}

.sub-header-menu {
    position: fixed;
    width: 100%;
    top: 75px;
    left: 0;
    background: #12032e 0% 0% no-repeat padding-box;
    opacity: 0.78;
    display: none;

    .sub-header-menu-items li {
        font: normal normal bold 17px/60px RalewayBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-right: 25px;
        border-right: 1px solid #d900a1;
        padding-right: 25px;
        margin-bottom: 14px;
        cursor: pointer;
    }

    .sub-header-menu-items ul {
        padding: 20px 0px;
        text-align: left;
    }

    .sub-header-menu-items li:last-child {
        border-right: 0;
    }
}

.sub-header-menu.active {
    display: block;
}
