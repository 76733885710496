.solutions-item-holder {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h2 {
        text-align: left;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 75px;
    }

    p {
        text-align: left;
        font: normal normal normal 16px/30px MontserratMedium;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 85px;
    }

    .solutions-item-main-block-content {
        padding: 170px 0px 160px 138px;
    }

    .solution-item {
        max-width: 430px;
    }
}

.solutions-content-holder-main-block {
    margin-bottom: 160px;

    h1 {
        text-align: center;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #12032e;
        opacity: 1;
        margin: 125px 0px;
    }
}

.info-block-holder-content {
    background: #f2f0ed;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 140px;
    padding-bottom: 140px;
    background: #f2f0ed 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px #00000029;
    opacity: 1;

    .info-main-content-center {
        max-width: 860px;
        text-align: center;
    }

    h3 {
        text-align: center;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #12032e;
        opacity: 1;
        margin: 0 auto;
        margin-bottom: 50px;
        max-width: 480px;
    }

    p {
        text-align: center;
        font: normal normal normal 20px/30px MontserratRegular;
        letter-spacing: 0px;
        color: #28193a;
        opacity: 1;
        margin-bottom: 50px;
    }
}

.hero-bottom-line-spacing {
    margin-top: -2px;
}
