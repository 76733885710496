@font-face {
  font-family: RalewayBold;
  src: url(../assets/fonts/raleway/Raleway-Bold.ttf);
}

@font-face {
  font-family: RalewayRegular;
  src: url(../assets/fonts/raleway/Raleway-Regular.ttf);
}

@font-face {
  font-family: MontserratMedium;
  src: url(../assets/fonts/montserrat/Montserrat-Medium.ttf);
}

@font-face {
  font-family: MontserratBold;
  src: url(../assets/fonts/montserrat/Montserrat-Bold.ttf);
}

@font-face {
  font-family: MontserratRegular;
  src: url(../assets/fonts/montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: MontserratMediumItalic;
  src: url(../assets/fonts/montserrat/Montserrat-MediumItalic.ttf);
}

@font-face {
  font-family: MontserratLightItalic;
  src: url(../assets/fonts/montserrat/Montserrat-LightItalic.ttf);
}

body {
  background-color: #ffffff !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 16.5rem !important;
}

.main-content-conteiner .container,
.main-content-conteiner .container-fluid,
.main-content-conteiner .container-lg,
.main-content-conteiner .container-md,
.main-content-conteiner .container-sm,
.main-content-conteiner .container-xl,
.main-content-conteiner .container-xxl {
  --bs-gutter-x: 1.5rem !important;
}

.hero-section-holder.des-block {
  width: 100%;
  height: calc(100vh - 95px);
  background: #00000070;

  #background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    filter: grayscale(70%);
  }

  .container-fluid,
  .row {
    height: 100%;
  }

  .hero-section-holder-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 110px;

    h2 {
      font: normal normal bold 70px/79px RalewayBold;
      color: rgba(219, 158, 124, 1);
      letter-spacing: 0px;
      opacity: 0.78;
      margin-bottom: 35px;
    }

    h1 {
      font: normal normal bold 179px/206px RalewayBold;
      letter-spacing: 0px;
      color: rgb(255, 255, 255);
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      margin-bottom: 45px;
      position: relative;
      z-index: 1;
      // border-right: 0.05em solid;
      // border-right-color: transparent linear-gradient(91deg, rgba(217, 0, 161, 1) 18%, rgba(101, 23, 200, 1) 100%)
      // 0% 0% no-repeat padding-box;
      overflow: hidden;
      white-space: nowrap;
      max-width: 1260px;
      // animation:
      //     typing 3.5s steps(40, end),
      //     blink-caret 0.75s step-end infinite;
    }

    h1::before {
      background: transparent
        linear-gradient(
          91deg,
          rgba(217, 0, 161, 1) 18%,
          rgba(101, 23, 200, 1) 100%
        )
        0% 0% no-repeat padding-box;
      opacity: 1;
      content: "";
      height: 148px;
      width: 497px;
      position: absolute;
      top: 28px;
      left: -5px;
      z-index: -1;
    }

    h3 {
      font: normal normal normal 20px/24px MontserratMedium;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      width: 398px;
    }
  }

  .button-absolute {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }
}

.hero-bottom-line-spacing {
  height: 92px;
  background: rgba(18, 3, 46, 1);
  position: relative;
}

.hero-bottom-line-spacing::before {
  content: "";
  z-index: 1;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 31px solid transparent;
  border-right: 31px solid transparent;
  border-bottom: 19px solid #12032f;
  top: 92px;
  left: 50%;
  transform: translate(-50%, 0) rotate(180deg);
}

.security-section-holder {
  background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
  opacity: 1;
  height: 880px;
  position: relative;

  .container,
  .row {
    height: 100%;
  }

  .security-section-holder-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 134px;
  }

  h2 {
    text-align: center;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgba(18, 3, 46, 1);
    opacity: 1;
  }

  h3 {
    text-align: center;
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: rgba(40, 25, 58, 1);
    opacity: 1;
    margin-top: 71px;
    margin-bottom: 123px;
  }

  .security-section-image {
    background: transparent url("../assets/Media/Group-758.png") no-repeat
      padding-box;
    height: 100%;
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center bottom 30px;
  }

  .security-section-content {
    z-index: 1;
  }
}

.shields-section-holder {
  background-image: url("../assets/Media/shields-bckg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1130px;

  .container-fluid,
  .row {
    height: 100%;
  }

  .shields-section-content {
    padding-left: 110px;
    margin-top: 143px;

    h2 {
      font: normal normal bold 57px/61px RalewayBold;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
    }

    h3 {
      width: 812px;
      text-align: left;
      font: normal normal normal 20px/30px MontserratMedium;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      margin-top: 50px;
      margin-bottom: 110px;
    }
  }

  .shields-section-content-list-items {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;

    .shields-section-content-item {
      // width: 295px;
      width: calc(20% - 30px);
      // margin-right: 41px;
    }

    .shields-section-content-item:nth-child(5) {
      margin-right: 0;
    }

    .shield-item-section {
      position: relative;

      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        font: normal normal bold 40px/61px RalewayBold;
        letter-spacing: 0px;
        color: rgb(255, 255, 255);
        text-transform: capitalize;
        opacity: 1;
        transform: translate(-50%, -70%);
      }

      h3 {
        font: normal normal bold 18px/30px MontserratMedium;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        opacity: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 211px;
      }
    }
  }

  .shield-item {
    transition: transform 1s ease;
  }

  .rotate-left {
    transform: rotateY(0deg);
  }

  .rotate-right {
    transform: rotateY(180deg);
  }

  .rotate-right h3 {
    transform: translate(-50%, -50%) scaleX(-1) !important;
    text-align: left !important;
  }

  .shields-section-content-down {
    margin-top: 82px;

    p {
      font: normal normal normal 16px/30px MontserratMedium;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      margin-bottom: 65px;
    }
  }
}

.soc-section-holder {
  padding: 330px 0px 230px 0px;
  background: rgba(242, 240, 237, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
  opacity: 1;

  .container-fluid,
  .row {
    height: 100%;
  }

  .soc-section-holder-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 175px;
  }

  .soc-section-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  .soc-section-content-left {
    width: 560px;
    height: 400px;
    margin-right: 100px;
    position: relative;
  }

  .soc-section-content-right {
    flex: 1;

    h2 {
      font: normal normal bold 57px/61px RalewayBold;
      letter-spacing: 0px;
      color: rgba(18, 3, 46, 1);
      opacity: 1;
      margin-bottom: 52px;
    }

    h3 {
      font: normal normal normal 16px/30px MontserratMedium;
      letter-spacing: 0px;
      color: rgba(40, 25, 58, 1);
      opacity: 1;
      margin-bottom: 52px;
    }
  }

  .soc-bckg-image {
    background-image: url("../assets/Media/soc.png");
    background-repeat: no-repeat;
    opacity: 1;
    width: 881px;
    height: 295px;
    position: absolute;
    top: -250px;
    left: 52%;
    z-index: -1;
    transform: translate(-50%, 0);
  }
}

.clients-section-holder {
  padding-top: 121px;
  padding-bottom: 60px;

  .clients-section-content {
    h2 {
      text-align: center;
      font: normal normal bold 57px/61px RalewayBold;
      letter-spacing: 0px;
      color: rgba(18, 3, 46, 1);
      opacity: 1;
      margin-bottom: 45px;
    }
  }
}

.why-section-holder {
  // background-image: url('../assets/Media/why-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .why-section-holder-content {
    padding-top: 300px;
    padding-bottom: 182px;
  }

  .why-section-content {
    display: flex;
    flex-direction: row;
    padding: 0px 110px;
  }

  .why-section-content-left {
    flex: 1;
  }

  .why-section-content-right {
    flex: 1;
    margin-top: 50px;
    margin-left: 50px;
  }

  h2 {
    text-align: left;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  h3 {
    text-align: left;
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    width: 530px;
    margin-bottom: 65px;
    margin-top: 45px;
  }

  .why-content-item-block {
    text-align: left;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
    background: transparent linear-gradient(89deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    padding: 0px 18px 11px 18px;
  }

  .list-items-why-content-title {
    position: relative;
  }

  .list-items-why-content-title.show-items-animation {
    .list-items-why-content-item {
      -webkit-animation: mainFadeIn2 2s forwards;
      -o-animation: mainFadeIn2 2s forwards;
      animation: mainFadeIn2 2s forwards;
      animation-delay: 1.6s;
    }

    .block {
      width: 0%;
      height: 72px;
      background: transparent linear-gradient(89deg, #d900a1 18%, #6517c8 100%)
        0% 0% no-repeat padding-box;
      position: absolute;
      animation: mainBlock2 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      display: flex;
      top: 10px;
    }
  }

  .list-items-why-content-item {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 45px;
    opacity: 0;
    position: relative;
  }

  h4 {
    text-align: left;
    font: normal normal normal 20px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-left: 40px;
  }

  .why-content-item-block span {
    margin-left: -12px;
  }
}

.info-section-holder {
  p {
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    margin-bottom: 52px;
    margin-top: 50px;
    padding: 0px 200px;
  }

  .flickity-prev-next-button.next {
    background-image: url("../assets/Media/right.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 49px;
    width: 24px;
  }

  .flickity-prev-next-button.previous {
    background-image: url("../assets/Media/left.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 49px;
    width: 24px;
    z-index: 2;
  }

  .flickity-prev-next-button.previous:before {
    content: "" !important;
  }

  .flickity-prev-next-button.next:before {
    content: "" !important;
  }

  .slick-next {
    background-image: url("../assets/Media/right.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 49px;
    width: 24px;
  }

  .slick-prev {
    background-image: url("../assets/Media/left.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 49px;
    width: 24px;
    z-index: 2;
  }

  .slick-prev:before {
    content: "" !important;
  }

  .slick-next:before {
    content: "" !important;
  }
}

.partners-section-holder {
  padding-top: 160px;
  padding-bottom: 130px;

  .flickity-prev-next-button.next {
    background-image: url("../assets/Media/right.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 49px;
    width: 24px;
  }

  .flickity-prev-next-button.previous {
    background-image: url("../assets/Media/left.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 49px;
    width: 24px;
    z-index: 2;
  }

  .flickity-prev-next-button.previous:before {
    content: "" !important;
  }

  .flickity-prev-next-button.next:before {
    content: "" !important;
  }

  .flickity-button-icon {
    display: none;
  }

  .flickity-prev-next-button.previous {
    left: 130px;
    top: 85%;
    background: transparent;
  }

  .flickity-prev-next-button.next {
    top: 85%;
    right: 84%;
    background: transparent;
  }

  h2 {
    text-align: center;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 123px;
  }

  .partner-itme-holder {
    display: flex;
  }

  .carouse-partner-content {
    height: 603px;
    position: relative;
  }

  .partner-item-left-side {
    background-image: url("../assets/Media/11.png");
    height: 603px;
    width: 950px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .custom-next-button {
    border: none;
    background: transparent;
    top: 82%;
    position: absolute;
    z-index: 2;
    left: 215px;
  }

  .custom-prev-button {
    background: transparent;
    border: none;
    position: absolute;
    top: 82%;
    left: 130px;
    z-index: 2;
  }

  .partner-item-left-right {
    height: 603px;
    width: 660px;
    margin-left: 20px;
    position: relative;
  }

  .carousel {
    height: 603px;
    padding: 0 !important;
  }

  .flickity-viewport {
    height: 603px !important;
  }

  .partner-item-image {
    position: absolute;
    left: -150px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .partner-item {
    width: 100%;
  }

  .carouse-partner-content {
    height: 603px;
  }

  .carousel {
    height: 603px;
  }

  .flickity-viewport {
    height: 603px !important;
  }

  p {
    text-align: left;
    font: normal normal normal 16px/30px MontserratMediumItalic;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
  }

  h3 {
    text-align: left;
    font: normal normal bold 30px/24px RalewayBold;
    letter-spacing: 0px;
    color: rgba(209, 191, 169, 1);
    margin-top: 50px;
    margin-bottom: 10px;
  }

  h4 {
    text-align: left;
    font: normal normal normal 20px/24px RalewayRegular;
    letter-spacing: 0px;
    color: rgba(209, 191, 169, 1);
  }

  .partner-item-left-side-holder {
    padding-left: 136px;
    padding-top: 103px;
    width: 575px;
  }
}

.info-section-holder {
  height: 540px;
  background-image: url("../assets/Media/pozadina.png");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;

  .info-section-content {
    text-align: center;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    max-width: 800px;
    margin: 0 auto;
  }
}

.footer-content-holder.des-block {
  background: rgba(18, 3, 46, 1) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(112, 112, 112, 1);
  opacity: 1;
  height: 136px;

  .container-fluid,
  .row {
    height: 100%;
  }

  .footer-content-holder-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .left-side-footer {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
  }

  .right-side-footer {
    flex: 1;
    display: flex;
    justify-content: left;
    align-content: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    text-align: left;
    font: normal normal normal 15px/26px RalewayRegular;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    margin-left: 37px;
  }

  .footer-items-list-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-reserved-item {
    text-align: left;
    font: normal normal normal 15px/26px MontserratRegular;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    text-transform: capitalize;
    opacity: 1;
    margin-left: 55px;
  }

  .footer-logo-item img {
    max-width: 140px;
  }

  .footer-social-item {
    display: flex;
    margin-left: 125px;
  }

  .footer-social-item-icon img {
    height: 18px;
    margin-left: 10px;
    padding-left: 20px;
    margin-right: 25px;
  }

  .footer-social-item-icon {
    border-left: 1px solid rgb(217, 0, 161, 0.31);
  }
}

.contact-us-holder-main {
  background: #f2f0ed 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px #00000029;
  opacity: 1;
  padding-top: 164px;
  padding-bottom: 164px;

  h2 {
    text-align: center;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 55px;
  }

  p {
    text-align: center;
    font: normal normal normal 16px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    padding: 0px 115px;
    margin-bottom: 0px;
  }

  .contact-form-info-messages {
    margin: 38px 0px;
    height: 30px;

    .text-form-message {
      color: #fff;
      background: rgb(18, 3, 46) 0% 0% no-repeat padding-box;
      padding: 6px 20px;
      font: normal normal normal 15px/26px RalewayRegular;
      opacity: 0;
      transition: 1s opacity;
    }

    .text-form-message.active {
      opacity: 1;
    }
  }

  .form-control {
    border-radius: 0;
    margin-bottom: 28px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 68px;
    text-align: left;
    font: italic normal 300 20px/24px MontserratLightItalic !important;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 0.57;
  }

  textarea {
    height: 260px !important;
  }

  .submit-form-button {
    text-align: center;
    margin-top: 60px;
  }

  .MuiInputBase-root::before {
    border-bottom: none !important;
  }
}

.back-to-top-holder {
  position: fixed;
  bottom: 54px;
  left: 49px;
  cursor: pointer;
}

.has-error-input {
  border: 1px solid #d000a3 !important;
}

.main-content-site-holder {
  margin-top: 75px;
}

.cyber-info-hero-holder-content {
  background: #f2f0ed 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px #00000029;
  opacity: 1;

  .cyber-info-hero-holder {
    position: relative;
    padding: 120px 0px 130px 120px;
  }

  h2 {
    text-align: left;
    font: normal normal bold 25px/40px MontserratBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    margin-bottom: 35px;
  }

  .cyber-info-hero-holder {
    padding: 100px 310px 100px 120px;
  }
}

.service-hero-holder-content-ai {
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  padding: 100px 0px 190px 0px;

  .service-hero-holder {
    padding: 0px 0px 0px 120px;
  }

  h1 {
    background: transparent
      linear-gradient(
        270deg,
        rgba(217, 0, 161, 1) 0%,
        rgba(56, 67, 203, 1) 100%
      )
      0% 0% no-repeat padding-box;
    font: normal normal bold 70px/90px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    display: inline;
    padding-left: 10px;
    padding-right: 10px;
  }

  h3 {
    font: normal normal bold 25px/40px MontserratMedium;
    margin-bottom: 20px;
  }
}

.service-hero-holder-content {
  // background-image: url('../assets/Media/services-hero.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h1 {
    opacity: 0;
  }

  .show-animation h1 {
    text-align: left;
    font: normal normal bold 70px/90px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    // max-width: 756px;
    // max-width: 890px;
    position: relative;
    z-index: 1;

    -webkit-animation: mainFadeIn 2s forwards;
    -o-animation: mainFadeIn 2s forwards;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    align-items: baseline;
    position: relative;

    span {
      width: 0px;
      height: 0px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

      background: transparent linear-gradient(101deg, #d900a1 18%, #6517c8 100%)
        0% 0% no-repeat padding-box;
      -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      animation-delay: 2s;
      margin-left: 5px;
      margin-top: -10px;
      position: absolute;
      bottom: 13px;
      right: -12px;
    }
  }

  .show-first-animation h1 {
    text-align: left;
    font: normal normal bold 70px/90px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    // max-width: 756px;
    // max-width: 890px;
    position: relative;
    z-index: 1;

    -webkit-animation: mainFadeIn 2s forwards;
    -o-animation: mainFadeIn 2s forwards;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    align-items: baseline;
    position: relative;

    span {
      width: 0px;
      height: 0px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

      background: transparent linear-gradient(101deg, #d900a1 18%, #6517c8 100%)
        0% 0% no-repeat padding-box;
      -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      animation-delay: 2s;
      margin-left: 5px;
      margin-top: -10px;
      position: absolute;
      bottom: 13px;
      right: -12px;
    }
  }

  .service-hero-holder {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;

    .block {
      width: 0%;
      height: inherit;
      background: transparent linear-gradient(101deg, #d900a1 18%, #6517c8 100%)
        0% 0% no-repeat padding-box;
      position: absolute;
      display: flex;
      z-index: 1;
      height: 75px;
    }

    .block.show-animation {
      animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    }

    .show-first-animation {
      animation: mainBlock2 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    }
  }

  h1::after {
    content: "";
    background: transparent linear-gradient(101deg, #d900a1 18%, #6517c8 100%)
      0% 0% no-repeat padding-box;
    opacity: 1;
    width: 26px;
    position: absolute;
    left: -13px;
    height: 109px;
    top: -10px;
    z-index: -1;
  }

  .service-hero-holder {
    padding: 240px 0px 260px 120px;
    max-width: 1020px;
  }

  .hero-title-large {
    max-width: 1400px !important;
  }
}

.title-description-holder-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px 0px 50px 0px;

  .title-description-body {
    text-align: center;
    max-width: 720px;
  }

  h2 {
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 80px;
  }

  p {
    text-align: center;
    font: normal normal bold 16px/30px MontserratBold;
    letter-spacing: 0px;
    color: #28193a;
    opacity: 1;
  }
}

.image-block-holder {
  text-align: center;
  margin-top: 55px;
  margin-bottom: 150px;
}

.block-image-item-holder {
  display: flex;
  justify-content: left;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h2 {
    text-align: left;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 75px;
  }

  p {
    text-align: left;
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 85px;
  }

  .block-image-main-block-content {
    padding: 170px 0px 160px 138px;
  }

  .block-image-item {
    max-width: 675px;
  }
}

.related-services-holder-content {
  background: transparent linear-gradient(70deg, #281d42 0%, #6517c8 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  padding: 90px 0px;

  .custom-info-button {
    margin-top: 45px;
  }

  h3 {
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    margin-bottom: 40px;
  }

  .related-services-content-center {
    padding: 0 200px;
    text-align: center;
  }

  .related-services-item-title {
    font: normal normal bold 20px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    // text-transform: capitalize;
    opacity: 1;
  }

  .related-services-list-items {
    display: flex;
    justify-content: space-between;
  }

  .related-services-item {
    padding: 45px 40px;
    border-right: 1px solid #fff;
    flex: 1;
    text-align: center;
  }

  .related-services-item:last-child {
    border-right: 0;
  }
}

.hero-bottom-line-blue {
  background-color: #0a0a46;
}

.resource-services-holder-content {
  background-image: url(../assets/Media/resources.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
  padding: 90px 0px;

  .custom-info-button {
    margin-top: 45px;
  }

  h3 {
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    text-align: center;
    margin-bottom: 40px;
  }

  .resource-services-content-center {
    padding: 0 310px;
    text-align: center;
  }

  .resource-services-item-title {
    font: normal normal bold 20px/61px RalewayBold;
    letter-spacing: 0px;
    color: #0a0a50;
    text-transform: capitalize;
    opacity: 1;
  }

  .resource-services-list-items {
    display: flex;
    justify-content: space-between;
  }

  .resource-services-item {
    padding: 45px 40px;
    border-right: 1px solid #0a0a50;
    flex: 1;
    text-align: center;
  }

  .resource-services-item:last-child {
    border-right: 0;
  }
}

.about-holder {
  .why-section-holder .list-items-why-content-item {
    margin-bottom: 30px;
  }

  .why-section-holder .why-section-holder-content {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.team-holder-content-main {
  padding-bottom: 80px;

  .team-item-name {
    text-align: left;
    font: normal normal bold 30px/24px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    margin-top: 60px;
    margin-bottom: 21px;
  }

  .team-item-position {
    text-align: left;
    font: normal normal normal 20px/24px RalewayRegular;
    letter-spacing: 0px;
    color: #281d42;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .team-item-description {
    text-align: left;
    font: normal normal bold 16px/30px MontserratBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
  }

  .team-items-list {
    display: flex;
  }

  .team-list-item {
    margin-right: 62px;
    flex: 1;
    max-width: 33.3333%;
  }

  .team-item-image img {
    max-width: 300px;
  }

  .team-item-line {
    width: 192px;
    background-color: #db9e7c;
    height: 2px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
}

.partner-holder-content-main {
  padding-bottom: 80px;

  .partner-item-name {
    text-align: left;
    font: normal normal bold 30px/24px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    margin-top: 60px;
    margin-bottom: 21px;
  }

  .partner-item-position {
    text-align: left;
    font: normal normal normal 20px/24px RalewayRegular;
    letter-spacing: 0px;
    color: #281d42;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .partner-item-description {
    text-align: left;
    font: normal normal bold 16px/30px MontserratBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
  }

  .partner-items-list {
    gap: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .partner-list-item {
    width: calc(33.3333% - 60px);
  }

  .partner-item-line {
    width: 192px;
    background-color: #db9e7c;
    height: 2px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .partner-main-body {
    margin-top: 80px;
  }
}

.team-big-holder-content-main {
  background-color: #f2f0ed;

  .team-big-list-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 120px;

    .team-big-item-holder {
      padding: 114px 260px;
      position: relative;
    }

    .team-big-item-image {
      position: absolute;
      top: 50%;
      left: -133px;
      transform: translate(0%, -50%);
    }

    .team-big-item-name {
      text-align: left;
      font: normal normal bold 30px/24px RalewayBold;
      letter-spacing: 0px;
      color: #d1bfa9;
    }

    .team-big-item-position {
      text-align: left;
      font: normal normal normal 20px/24px RalewayRegular;
      letter-spacing: 0px;
      margin-top: 10px;
      color: #d1bfa9;
      margin-bottom: 40px;
    }

    .team-big-item-description {
      text-align: left;
      font: normal normal bold 14px/24px MontserratBold;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .team-big-main-body {
      padding: 0px 130px;
    }

    .team-big-item-image-right {
      position: absolute;
      top: 50%;
      right: -133px;
      transform: translate(0%, -50%);
    }
  }

  .team-big-main-body {
    padding: 120px 150px 0px 150px;
  }
}

.des-block {
  display: block;
}

.mob-block {
  display: none;
}

.client-item {
  justify-content: center;
  display: flex !important;
  align-items: center;

  img {
    max-height: 155px;
    filter: grayscale(100%);
    opacity: 0.5;
  }

  &:hover img {
    opacity: 1;
    filter: none;
  }
}

.carouse-client-content {
  position: relative;
}

img.icon-slider-left {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

img.icon-slider-right {
  position: absolute;
  top: 100px;
  right: 0;
  z-index: 2;
  cursor: pointer;
}

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 30px;
  height: 0px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 0px;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 2 */

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #fff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* Icon 4 */

#nav-icon4 {
  background: red;
}

#nav-icon4 span:nth-child(1) {
  top: 12px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 28px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 12px;
  left: 7px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 34px;
  left: 6px;
}

.header-menu-mobile {
  display: flex;
  justify-content: space-between;
}

.header-items-list-languages.header-lanaguage-mobile {
  padding: 10px 0px 20px 0px;
}

.header-menu-mobile-icon img {
  height: 49px;
  margin-top: 6px;
  margin-left: 15px;
}

.header-content-main {
  box-shadow: 0 4px 8px -4px #12032f;
}

.footer-items-list-left li {
  cursor: pointer;

  a {
    text-decoration: none;
    color: #fff;
  }
}

.footer-mobile-block-list-menu li {
  cursor: pointer;

  a {
    text-decoration: none;
    color: #fff;
  }
}

.expand-list-item-header h4 {
  font: normal normal bold 30px/61px RalewayBold;
  letter-spacing: 0px;
  color: #281d42;
  opacity: 1;
}

.header-items-list-left li span {
  margin-right: 7px;
  width: 20px;
}

.header-items-list-left li span path {
  stroke: #fff;
  margin-right: 7px;
}

.header-items-list-left li svg {
  width: 17px;
}

.header-items-list-right li span {
  margin-right: 7px;
  width: 20px;
}

.header-items-list-right li span path {
  stroke: #fff;
  margin-right: 7px;
}

.header-items-list-right li svg {
  width: 17px;
}

.button-holder-content a {
  text-decoration: none;
}

.hero-error-page {
  height: calc(100vh - 211px) !important;
}

.guardian-holder-content-left li {
  margin-bottom: 30px;
}

.page-holder-main-content .title-description-body {
  max-width: 1088px;
}

.button-holder-item-description {
  margin-top: 100px;
  margin-bottom: 100px;
}

.image-holder-cyber-info-hero-holder {
  margin-top: 70px;
}

.guardin-holder-content-main-circle {
  background: #f2f0ed 0% 0% no-repeat padding-box;
  border: 3px solid #0a0a50;
  opacity: 1;
  width: 179px;
  height: 179px;
  font: normal normal bold 43px/45px MontserratBold;
  letter-spacing: 0px;
  color: #281d42;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.guardin-holder-content-circle {
  border-radius: 100%;
  background: #f2f0ed 0% 0% no-repeat padding-box;
  border: 2px solid #d900a1;
  opacity: 1;
  font: normal normal bold 12px/16px MontserratBold;
  letter-spacing: 0px;
  color: #281d42;
  opacity: 1;
  width: 132px;
  height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
}

.guardin-holder-content-circle-1 {
  top: -150px;
}

.guardian-holder-content-circle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 150px;
}

.guardin-holder-content-circle.guardin-holder-content-circle-2 {
  top: -50px;
  left: 180px;
}

.guardin-holder-content-circle.guardin-holder-content-circle-3 {
  bottom: -95px;
  left: 160px;
}

.guardin-holder-content-circle.guardin-holder-content-circle-4 {
  bottom: -95px;
  left: -125px;
}

.guardin-holder-content-circle.guardin-holder-content-circle-5 {
  top: -50px;
  left: -140px;
}

.guardin-holder-content-circle.guardin-holder-content-circle-1::after {
  content: "";
  background: #707070;
  width: 1px;
  height: 18px;
  position: absolute;
  bottom: -18px;
}

.guardin-holder-content-circle.guardin-holder-content-circle-2::after {
  content: "";
  background: #707070;
  width: 1px;
  height: 20px;
  position: absolute;
  left: -4px;
  bottom: 18px;
  transform: rotate(55deg);
}

.guardin-holder-content-circle.guardin-holder-content-circle-3::after {
  content: "";
  background: #707070;
  width: 1px;
  height: 29px;
  position: absolute;
  left: 3px;
  top: -2px;
  transform: rotate(-44deg);
}

.guardin-holder-content-circle.guardin-holder-content-circle-4::after {
  content: "";
  background: #707070;
  width: 1px;
  height: 33px;
  position: absolute;
  right: 3px;
  top: -7px;
  transform: rotate(45deg);
}

.guardin-holder-content-circle.guardin-holder-content-circle-5::after {
  content: "";
  background: #707070;
  width: 1px;
  height: 25px;
  position: absolute;
  right: -3px;
  bottom: 13px;
  transform: rotate(-55deg);
}

.bold-text {
  font-weight: bold !important;
  text-decoration: underline;
}

.header-menu-mobile-icon {
  z-index: 2;
}

.header-menu-mobile::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 31px solid transparent;
  border-right: 31px solid transparent;
  border-bottom: 19px solid #12032f;
  top: 45px;
  left: 53px;
  transform: translate(-50%, 0) rotate(180deg);
}

.related-services-item-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header-menu-mobile::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 31px solid transparent;
  border-right: 31px solid transparent;
  border-bottom: 19px solid #12032f;
  top: 45px;
  left: 53px;
  transform: translate(-50%, 0) rotate(180deg);
}

.header-menu-mobile-icon {
  z-index: 2;
}

.related-services-item-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.guardian-holder-content-left h1 {
  text-align: left;
  font: normal normal bold 57px/61px RalewayBold;
  letter-spacing: 0px;
  color: #0a0a50;
  opacity: 1;
  margin-bottom: 30px;
}

.serive-our-custom-template-text-block {
  background: #f2f0ed 0% 0% no-repeat padding-box;
  padding: 136px 138px;
}

.serive-our-custom-template-text-block p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.serive-our-custom-template-text-block h1 {
  font: normal normal bold 57px/61px RalewayBold;
  letter-spacing: 0px;
  color: #0a0a50;
  opacity: 1;
  margin-bottom: 93px;
}

.serive-our-custom-template-text-block p {
  font: normal normal normal 16px/30px MontserratMedium;
  letter-spacing: 0px;
  color: #0a0a50;
  opacity: 1;
  max-width: 880px;
}

.serive-our-custom-template-image-block {
  padding: 130px 138px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.service-our-service-custom-template-holder {
  margin-bottom: 60px;
}

.serive-our-custom-template-image-block ul {
  font: normal normal bold 18px/30px MontserratBold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.serive-our-custom-template-image-block ul li {
  margin-bottom: 37px;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: rgb(219, 158, 124);
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainBlock2 {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mainFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }

  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }

  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }

  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }

  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.partners-holder .partner-item-image img {
  max-height: 90px;
}

.cyber-mobile-block {
  position: fixed;
  bottom: 90px;
  display: flex;
  right: 15px;
  z-index: 2;
}

.cyber-mobile-block-text {
  font: normal normal bold 15px/16px RalewayBold;
  letter-spacing: 0px;
  color: #ffffff;
  background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding: 14px 8px;
  align-items: center;
  position: absolute;
  right: 51px;
  width: 212px;
  top: 17px;
  opacity: 0;
  transform: translateX(20%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateX(230px);
}

.cyber-mobile-block img {
  height: 80px;
  z-index: 2;
}

.cyber-mobile-block.show .cyber-mobile-block-text {
  opacity: 1;
  transform: translateX(0);
}

.cyber-mobile-block-text a {
  color: #fff;
}

.cyber-security-icon-holder {
  position: relative;
}

.cyber-security-icon-holder span {
  position: absolute;
  color: #f7cba8;
  top: 27px;
  left: 20px;
  font: normal normal normal 36px/24px MontserratMedium;
}

.shield-content-holder {
  position: absolute;
  top: 35px;
  right: 45px;
}

.shield-content-holder img {
  height: 180px;
}

.shield-content-holder span {
  position: absolute;
  top: 50%;
  left: 50%;
  font: normal normal bold 22px/61px RalewayBold;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  opacity: 1;
  transform: translate(-50%, -55%);
}

@keyframes mainBlock2 {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.related-services-item a {
  color: #fff;
}

.description-error-text {
  position: relative;
}

a.special-partner-link {
  font-weight: bold;
}

.color-black-special-text {
  color: #0a0a50;
}

.color-white-special-text {
  color: #fff;
}

.partner-item-name a {
  color: #281d42;
  cursor: pointer;
  text-decoration: none;
}

.list-items-guardian-lists li.active {
  font-weight: bold !important;
}

.container-serive-image-text-block a {
  text-decoration: none;
  color: #fff;
}

.soc-section-content-left-item {
  border: 4px solid #db9e7c;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 20px/30px MontserratMedium;
  color: rgb(18, 3, 46);
  background: #f2f0ed;
  z-index: 2;
}

.soc-section-content-left-item-1 {
  border: 3px solid #db9e7c;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 38px/41px MontserratMedium;
  color: rgb(18, 3, 46);
  padding: 0px 57px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.soc-section-content-left-item-3 {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0);
}

.soc-section-content-left-item-2 {
  position: absolute;
  top: 50%;
  left: 5%;
}

.soc-section-content-left-item-4 {
  position: absolute;
  top: 50%;
  right: 5%;
}

.image-block-holder-items {
  position: relative;
  height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/Media/soc-item-backgorund-2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.image-block-holder-item-1.image-block-holder-item {
  position: absolute;
  top: -190px;
  left: 50%;
  transform: translate(-50%, 0);
}

.image-block-holder-item-2.image-block-holder-item {
  position: absolute;
  top: -110px;
  right: -150px;
  transform: translate(0%, 0%);
}

.image-block-holder-item-3.image-block-holder-item {
  position: absolute;
  top: 50%;
  right: -220px;
  transform: translate(0%, -50%);
}

.image-block-holder-item-4.image-block-holder-item {
  position: absolute;
  bottom: -90px;
  right: -148px;
  transform: translate(0%, 0%);
}

.image-block-holder-item-5.image-block-holder-item {
  position: absolute;
  bottom: -180px;
  left: 50%;
  transform: translate(-50%, 0);
}

.image-block-holder-item-6.image-block-holder-item {
  position: absolute;
  bottom: -95px;
  left: -150px;
  transform: translate(0%, 0%);
}

.image-block-holder-item-7.image-block-holder-item {
  position: absolute;
  top: 50%;
  left: -220px;
  transform: translate(0%, -50%);
}

.image-block-holder-item-8.image-block-holder-item {
  position: absolute;
  top: -110px;
  left: -150px;
  transform: translate(0%, 0%);
}

.image-block-holder-item-center {
  position: relative;
}

.image-block-holder-item .tekst {
  font: normal normal bold 18px/30px MontserratMedium;
  color: #28193a;
}

.image-block-holder-item {
  position: relative;
}

.image-block-holder-item-1.image-block-holder-item .tekst {
  position: absolute;
  top: -40%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 240px;
}

.image-block-holder-item-5.image-block-holder-item .tekst {
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 240px;
}

.image-block-holder-item-2.image-block-holder-item .tekst {
  position: absolute;
  top: 50%;
  right: -115px;
  transform: translate(0%, -50%);
}

.image-block-holder-item-3.image-block-holder-item .tekst {
  position: absolute;
  top: 50%;
  right: -183px;
  transform: translate(0%, -50%);
  width: 190px;
}

.image-block-holder-item-4.image-block-holder-item .tekst {
  position: absolute;
  top: 50%;
  right: -177px;
  transform: translate(0%, -50%);
}

.image-block-holder-item-6.image-block-holder-item .tekst {
  position: absolute;
  top: 50%;
  left: -210px;
  transform: translate(0%, -50%);
}

.image-block-holder-item-7.image-block-holder-item .tekst {
  position: absolute;
  top: 50%;
  left: -113px;
  transform: translate(0%, -50%);
}

.image-block-holder-item-8.image-block-holder-item .tekst {
  position: absolute;
  top: 50%;
  left: -177px;
  transform: translate(0%, -50%);
}

.title-item-center-soc-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font: normal normal bold 22px/28px MontserratMedium;
}

.partner-item-left-right img {
  object-fit: cover;
  height: 100%;
}

.grecaptcha-badge {
  z-index: 10;
}

.image-block-holder img {
  max-width: 80%;
}

.menu-open {
  overflow: hidden;
}
.special-block-info-description-landing {
  background-color: #fff;
  h2 {
    font: normal normal 18px/30px MontserratRegular !important;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
  }
  .cyber-info-hero-holder {
    padding-right: 480px;
  }
}
.block-special-item-number-landing-holder {
  padding: 90px 310px 90px 120px !important;
  background-image: url(../assets//Media/bckg-23.png);
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 125px;
  h1 {
    text-align: left;
    font: normal normal bold 52px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 52px;
    width: 715px;
    margin-bottom: 35px;
  }
  h2 {
    /* UI Properties */
    text-align: left;
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 16px;
    padding-right: 150px;
  }
}
.block-bckg-item-full-width {
  background-image: url(../assets//Media/bckg-22.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.related-service-holder-landing {
  background: #fff !important;
  h3 {
    color: #12032e !important;
  }
  .related-services-item-title {
    text-align: center;
    font: normal normal bold 100px/61px RalewayBold !important;
    letter-spacing: 0px;
    color: #281d42 !important;
    opacity: 1;
    font-size: 100px;
    margin-bottom: 45px;
  }
  .related-services-item-desc {
    /* UI Properties */
    text-align: center;
    font: normal normal normal 25px/30px MontserratRegular !important;
    letter-spacing: 0px;
    color: #281d42 !important;
    opacity: 1;
  }

  .related-services-item {
    border-right: 3px solid #d900a1 !important;
  }
  .related-services-item:last-child {
    border-right: 0 !important;
  }
}
.related-services-holder-content .related-services-item-title:hover {
  text-decoration: none !important;
  cursor: auto !important;
}
.related-service-image-holder-landing {
  background-image: url(../assets/Media/bckg-24.png);
  background-repeat: no-repeat;
  background-size: cover !important;
  h3 {
    color: #fff !important;
  }
  h4 {
    text-align: center;
    font: normal normal bold 16px/30px MontserratRegular !important;
    letter-spacing: 0px;
    color: #ffffff !important;
    opacity: 1;
    width: 620px;
    margin: auto;
    margin-bottom: 35px;
  }
  .related-services-item-title {
    text-align: center;
    font: normal normal bold 100px/61px RalewayBold;
    letter-spacing: 0px;
    color: #fff !important;
    opacity: 1;
    font-size: 100px;
    margin-bottom: 45px;
  }
  .related-services-item-desc {
    /* UI Properties */
    text-align: center;
    font: normal normal normal 25px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
  }

  .related-services-item {
    border-right: 3px solid #fff;
  }
  .related-services-item-title:hover {
    text-decoration: none !important;
    cursor: auto !important;
  }
}
.title-description-holder-content-landing {
  padding: 150px px 0px 50px 0px;
  .title-description-body {
    max-width: 100%;
  }
}
.block-title-desc-image-holder {
  background-color: #f2f0ed;
  .title-description-holder-content {
    .title-description-body {
      max-width: 900px;
      h2 {
        color: #0a0a50;
      }
      p {
        color: #0a0a50;
        max-width: 720px;
        margin: auto;
        font: normal normal normal 16px/30px MontserratMedium !important;
      }
    }
  }
}
.block-custom-image-text-landing-content {
  display: flex;
}

.block-custom-image-text-landing-content-text {
  margin-left: 65px;
  flex: 1;
}
.block-custom-image-text-landing-content-image {
  flex: 1;
}

.block-custom-image-text-landing-content-text-title {
  text-align: left;
  font: normal normal bold 30px/61px RalewayBold;
  letter-spacing: 0px;
  color: #0a0a50;
  opacity: 1;
}
.block-custom-image-text-landing-content-text-button {
  margin-top: 80px;
}

.block-custom-image-text-ladingn-text {
  text-align: left;
  font: normal normal normal 16px/27px MontserratMedium;
  letter-spacing: 0px;
  color: #0a0a50;
}

.block-custom-image-text-ladingn-list-content {
  text-align: left;
  font: normal normal normal 25px / 40px MontserratBold;
  letter-spacing: 0px;
  color: #0a0a50;
  margin-top: 10px;
}

.block-custom-image-text-landing-holder {
  padding-bottom: 100px;
  padding-top: 70px;
}
.contact-form-landing-holder {
  height: 900px;
  width: 511px;
  background-color: #f2f0ed;
  position: absolute;
  right: 0;
  top: 470px;
  z-index: 2;
}
.contanct-form-landing-content {
  padding: 60px 40px;
  height: 100%;
  h3 {
    font-size: 30px;
    text-align: left;
    font: normal normal bold 30px/30px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
  }
  h4 {
    text-align: left;
    font: normal normal bold 16px/30px MontserratBold;
    letter-spacing: 0px;
    color: #28193a;
    opacity: 1;
    font-size: 16px;
  }
  .contact-form-lading-content-form {
    .form-control {
      border-radius: 0;
      margin-bottom: 28px;
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      height: 68px;
      text-align: left;
      font: italic normal 300 20px / 24px MontserratLightItalic !important;
      letter-spacing: 0px;
      color: #12032e;
      opacity: 0.57;
    }
    .custom-contact-button {
      width: 100%;
      padding: 25px 49px;
    }
  }
  textarea.form-control {
    height: 200px !important;
  }
}
.button-export-to-excel {
  margin: 15px 0px 15px 0px;
}
.penetration-testing-holder-main .service-hero-holder-content::before {
  content: "";
  background: #000000a8;
  width: 100%;
  height: 100%;
  position: absolute;
}

.penetration-testing-holder-main .service-hero-holder-content {
  position: relative;
}
.contanct-info-mobile-form {
  font: normal normal normal 18px / 30px MontserratMedium;
  margin-top: 15px;
}
.contanct-info-mobile-form a {
  color: #d800a1;
}
.related-services-item-title-small {
  font-size: 75px !important;
}
.service-hero-info-line {
  position: absolute;
  bottom: 20px;
  right: 90px;
  display: flex;
  align-items: center;
  gap: 25px;
  .service-hero-info-text {
    span {
      font: normal normal bold 25px/40px MontserratMedium;
    }
  }
  .service-hero-info-image {
    img {
      max-height: 50px;
    }
  }
  .service-hero-info-line-center {
    width: 2px;
    background-color: #fff;
    height: 40px;
  }
}
.info-holder-container-ai {
  padding: 100px 0px;
  .info-holder-ai {
    padding: 0px 310px 0px 120px;
    text-align: left;
    font: normal normal normal 25px / 40px MontserratMedium;
    letter-spacing: 0px;
    color: #0a0a50;
  }
}
.ai-section-4 {
  .ai-section-4-content {
    background-image: url("../assets/Media/section4.png");
    background-repeat: no-repeat;
    background-size: cover;

    color: #fff;
    text-align: center;
    padding: 150px 0px;

    h1 {
      font: normal normal bold 57px/61px RalewayBold;
      margin-bottom: 50px;
    }

    h3 {
      font: normal normal bold 18px/30px MontserratMedium;
      max-width: 850px;
      margin: 0 auto;
    }

    .ai-section-items-list {
      margin-top: 180px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 190px;
      gap: 116px;

      .ai-section-item {
        // max-width: 400px; /* Maksimalna širina za svaki element */
        flex: 1; /* Flex stavke će se ravnomerno rasporediti */
        // flex-basis: 150px; /* Početna širina svakog elementa */
        // overflow: hidden; /* Ako sadržaj prevaziđe, biće skriven */
        h4 {
          text-align: center;
          font: normal normal bold 25px/30px MontserratMedium;
        }
      }
    }
  }
}
.ai-section-5 {
  padding: 150px 0px;
  text-align: center;
  color: rgba(10, 10, 80, 1);

  h1 {
    font: normal normal bold 57px/61px RalewayBold;
    margin-bottom: 100px;
  }

  h3 {
    font: normal normal bold 18px/30px MontserratMedium;
    max-width: 750px;
    margin: 0 auto;

    span {
      font: normal normal bold 25px/30px MontserratMedium;
    }
  }
}
.ai-section-6 {
  .ai-section-6-content {
    img {
      width: 100%;
    }
  }
}
.ai-section-7 {
  padding: 50px 0 100px 0;
  .ai-section-7-items-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    color: rgba(10, 10, 80, 1);

    h3 {
      font: normal normal bold 25px/30px RalewayBold;
    }

    h4 {
      font: normal normal normal 18px/30px MontserratRegular;
      padding-bottom: 0px;
    }

    .ai-section-7-item {
      border-right: 3px solid rgba(10, 10, 80, 1);
      padding-right: 60px;
      padding-left: 40px;
      margin-bottom: 40px;

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9) {
        border: none;
      }
    }
  }
}
.ai-section-8 {
  background-image: url("../assets/Media/ai-8.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  padding: 500px 0px;
  position: relative;
  h2 {
    font: normal normal bold 57px/61px RalewayBold;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}
.ai-section-9 {
  color: rgba(10, 10, 80, 1);
  text-align: center;
  padding: 150px 0px 100px 0;
  background-color: #fff;

  h2 {
    margin-bottom: 100px;
    font: normal normal bold 25px/30px MontserratBold;
  }
  h3 {
    height: 60px;
    font: normal normal bold 25px/30px MontserratMedium;
  }
  h4 {
    text-align: center;
    font: normal normal normal 18px/30px MontserratRegular;
  }
  .ai-section-9-items-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 80px;
  }
}

.ai-section-10 {
  background-image: url("../assets/Media/section10.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 150px 0px 150px 120px;

  h2 {
    font: normal normal bold 57px/61px RalewayBold;
    margin-bottom: 30px;
  }
  h3 {
    max-width: 690px;
    font: normal normal medium 18px/30px MontserratRegular;
    margin-bottom: 80px;
  }
  h4 {
    font: normal normal bold 25px/70px RalewayRegular;
    padding: 0px 20px;
  }
  .ai-section-10-item {
    margin-bottom: 30px;
  }
  .text-item-ai-10 {
    margin-bottom: -5px;
    display: inline-block;
    background: transparent
      linear-gradient(
        89deg,
        rgba(217, 0, 161, 1) 18%,
        rgba(101, 23, 200, 1) 100%
      )
      0% 0% no-repeat padding-box;
  }
}

.mobile-block-item {
  display: none;
}
.ai-section-11 {
  color: rgba(10, 10, 80, 1);
  h2 {
    color: #fff;
  }
  img {
    width: 100%;
  }

  h3 {
    font: normal normal normal 18px / 30px MontserratMedium;
    margin-top: 50px;
  }

  h4 {
    font: normal normal bold 25px/25px RalewayRegular !important;
    padding: 0px 5px;
    margin-bottom: 40px;
  }
  .text-item-ai-11 {
    background: transparent
      linear-gradient(
        89deg,
        rgba(217, 0, 161, 1) 18%,
        rgba(101, 23, 200, 1) 100%
      )
      0% 0% no-repeat padding-box;
    display: inline-block;
    padding: 20px;
    margin-bottom: 20px;
    color: #fff;
    font: normal normal bold 25px/25px RalewayBold !important;
  }
  .ai-section-11-list-items {
    margin-top: 30px;
  }
}
.ai-section-11-image {
  position: relative;

  h2 {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    font: normal normal bold 30px/40px RalewayBold;
    text-align: center;

    width: 100%;
    padding: 0px 50px;
  }
}
.hero-section-ai-content-mobile {
  .hero-bottom-line-spacing {
    color: #fff;
    padding: 50px 15px;
  }

  .hero-bottom-line-spacing {
    height: auto;

    h2 {
      color: rgba(255, 255, 255, 1);
      font: normal normal bold 25px/30px RalewayBold;
      padding: 0;
    }

    h1 {
      font: normal normal bold 50px/50px RalewayBold;
      background: transparent
        linear-gradient(
          90deg,
          rgba(217, 0, 161, 1) 18%,
          rgba(101, 23, 200, 1) 100%
        )
        0% 0% no-repeat padding-box;
      display: inline-block;
    }

    &::before {
      content: none;
    }
    &::after {
      content: "";
      border-bottom: 19px solid #12032f;
      border-left: 31px solid transparent;
      border-right: 31px solid transparent;
      content: "";
      bottom: -19px;
      height: 0;
      left: 50%;
      position: absolute;
      transform: translate(-50%) rotate(180deg);
      width: 0;
      z-index: 1;
    }
  }
}
.hero-section-ai-logo-text {
  margin-top: 40px !important;
  text-align: center;

  img {
    max-width: 230px;
  }
  .hero-section-ai-logo-line {
    width: 120px;
    height: 2px;
    background-color: #fff;
    margin: 25px auto;
  }
  h3 {
    margin-top: 10px;
  }
}
.ai-section-88 {
  position: relative;
  h2 {
    text-align: center;
    position: absolute;
    color: #fff;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    font: normal normal 700 30px / 36px RalewayBold;
  }
}
