.text-button-image-bckg-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 338px 0px;

    .text-button-image-bckg-holder {
        text-align: center;
    }

    p {
        text-align: center;
        font: normal normal normal 20px/30px MontserratMedium;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        max-width: 820px;
    }
}

.images-block-content-holder {
    padding-bottom: 40px;

    .images-block-body {
        display: flex;
    }

    .images-block-holder {
        flex: 1;
        padding: 70px 145px 140px 145px;
    }

    .images-block-left-holder {
        background-image: url(../assets/Media/images-block-1.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-right: 20px;

        h2::before {
            background: transparent linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0% no-repeat padding-box;
            opacity: 1;
            content: "";
            height: 68px;
            width: 440px;
            position: absolute;
            top: 0px;
            left: 10px;
            z-index: -1;
        }
    }

    .images-block-right-holder {
        background-image: url(../assets/Media/images-block-2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        h2::before {
            background: transparent linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0% no-repeat padding-box;
            opacity: 1;
            content: "";
            height: 68px;
            width: 380px;
            position: absolute;
            top: 0px;
            left: 10px;
            z-index: -1;
        }
    }

    p {
        font: normal normal bold 16px/20px MontserratBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }

    h2 {
        text-align: left;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 55px;
        position: relative;
        z-index: 1;
    }
}

.four-blocks-holder-content {
    padding-bottom: 120px;

    .four-blocks-items-list {
        display: flex;
        flex-wrap: wrap;
    }

    .four-blocks-item {
        flex: 1 1;
        flex-basis: calc(50% - 20px);
        background: #f2f0ed 0% 0% no-repeat padding-box;
        border: 1px solid rgba(0, 0, 0, 0);
        opacity: 1;
        padding: 90px 139px 132px 139px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    h3 {
        text-align: left;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #281d42;
        opacity: 1;
        margin-bottom: 40px;
    }

    p {
        text-align: left;
        font: normal normal bold 16px/30px MontserratBold;
        letter-spacing: 0px;
        color: #281d42;
        opacity: 1;
    }
}

h4 {
    text-align: left;
    font: normal normal bold 30px/61px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    padding: 27px 0px;
}

.expand-list-content-holder {
    padding-bottom: 100px;

    .expand-list-item {
        border-bottom: 1px solid #12032e;
        opacity: 1;

        img {
            transition: transform 0.5s ease;
        }
    }

    p {
        font: normal normal normal 20px/30px MontserratRegular;
        letter-spacing: 0px;
        color: #12032e;
        opacity: 1;
        padding: 0px 0px 10px;
    }

    .expand-list-description.active {
        padding: 0px 80px;
    }

    .expand-list-item-header {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
    }

    .expand-list-description.active {
        display: block;
    }

    .expand-list-description {
        display: none;
    }

    .active-expand-item img {
        transform: rotate(90deg);
    }
}

.expertise-main {
    .expand-list-content-holder .expand-list-item-header {
        cursor: auto;
    }
}