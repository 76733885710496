.mission-vision-content-holder-block {
    .mission-item {
        background-image: url('../assets/Media/misija.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .mission-vision-item-holder::after {
            content: "";
            padding-bottom: 2px;
            width: 135px;
            background: #0A0A50;
            display: inline-block;
            margin-top: 80px;
        }

        h1 {
            text-align: center;
            font: normal normal bold 131px/151px RalewayBold;
            letter-spacing: 0px;
            color: #0A0A50;
            opacity: 1;
            margin-bottom: 40px;
        }

        p {
            text-align: center;
            font: normal normal normal 20px/30px MontserratMedium;
            letter-spacing: 0px;
            color: #0A0A50;
            opacity: 1;
        }
    }

    .mission-vision-item {
        height: 1060px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mission-vision-item-holder {
        text-align: center;
    }

    .vision-item {
        background-image: url('../assets/Media/vizija.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // background-color: rgb(26, 26, 26);

        .mission-vision-item-holder::after {
            content: "";
            padding-bottom: 2px;
            width: 135px;
            background: #fff;
            display: inline-block;
            margin-top: 80px;
        }

        h1 {
            text-align: center;
            font: normal normal bold 131px/151px RalewayBold;
            letter-spacing: 0px;
            color: #fff;
            opacity: 1;
            margin-bottom: 40px;
        }

        p {
            text-align: center;
            font: normal normal normal 20px/30px MontserratMedium;
            letter-spacing: 0px;
            color: #fff;
            opacity: 1;
        }
    }
}