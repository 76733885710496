.button-item {
	border: none;
}

.custom-buttom-hero {
	background: transparent linear-gradient(91deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
	color: #fff;
	padding: 26px 41px;
	font: normal normal bold 20px/21px RalewayBold;
	opacity: 0.79;
	position: fixed;
	bottom: 90px;
	right: 50px;
	z-index: 2;
}

.custom-button-gold {
	width: 395px;
	height: 42px;
	background: transparent linear-gradient(81deg, rgb(174, 112, 79) 0%, rgb(253, 206, 173) 100%) 0% 0% no-repeat
		padding-box;
	opacity: 1;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;
	opacity: 1;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-button-gold2 {
	width: 257px;
	height: 42px;
	/* UI Properties */
	background: transparent linear-gradient(81deg, rgba(174, 112, 79, 1) 0%, rgba(253, 206, 173, 1) 100%) 0% 0%
		no-repeat padding-box;
	opacity: 1;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;
	opacity: 1;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-security-button {
	width: 257px;
	height: 42px;
	/* UI Properties */
	background: transparent linear-gradient(90deg, rgba(217, 0, 161, 1) 18%, rgba(101, 23, 200, 1) 100%) 0% 0% no-repeat
		padding-box;
	opacity: 1;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: #0a0a50 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-info-button {
	border: 1px solid rgba(255, 255, 255, 1);
	opacity: 1;
	background: transparent;
	width: 320px;
	height: 42px;
	margin-top: 85px;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;
	opacity: 1;
}

.custom-button-why {
	width: 302px;
	height: 42px;
}

.custom-contact-button {
	// width: 257px;
	padding: 12px 49px;
	// height: 42px;
	/* UI Properties */
	background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: #ffffff;
	text-transform: uppercase;
	opacity: 1;
	text-align: center;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: #0a0a50 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-services-button {
	height: 42px;
	/* UI Properties */
	background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: #ffffff;
	text-transform: uppercase;
	opacity: 1;
	text-align: center;
	padding: 0px 40px;
	margin-top: 70px;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: #0a0a50 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-button-soc {
	background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: #ffffff;
	text-transform: uppercase;
	opacity: 1;
	text-align: center;
	padding: 12px 55px;
	margin-top: 70px;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: #0a0a50 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-buttom-gold {
	text-align: center;
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: #ffffff;
	text-transform: uppercase;
	opacity: 1;
	/* UI Properties */
	background: transparent linear-gradient(81deg, #ae704f 0%, #fdcead 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
	padding: 12px 43px;

	display: inline-block;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
	}
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0% 0% no-repeat padding-box;
		transition: all 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}

.custom-resources-button {
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: #0a0a50;
	text-transform: uppercase;
	opacity: 1;
	background: transparent;
	border: 2px solid #0a0a50;
	padding: 10px 40px;
	margin-top: 30px;
}

.custom-buttom-preview-expertise {
	font: normal normal bold 18px/19px RalewayBold;
	letter-spacing: 1.8px;
	color: #0a0a50;
	text-transform: uppercase;
	opacity: 1;
	background: transparent;
	border: 2px solid #0a0a50;
	padding: 10px 40px;
	margin-top: 30px;
}

.custom-resources-button span {
	margin-left: 10px;
}
