.expertise-main {
    padding-bottom: 80px;

    .expand-list-content-holder {
        padding-bottom: 0px;
    }

    .button-holder-content button {
        width: 206px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
    }
}