.two-block-image-text-holder-content {
    background: #12032E 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 150px 100px;

    .two-block-image-text-body {
        display: flex;
    }

    .two-block-image-text-left {
        flex: 1;
        text-align: right;
        margin-right: 80px;
    }

    .two-block-image-text-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h2 {
        text-align: left;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 60px;
        padding-right: 100px;
    }

    p {
        text-align: left;
        font: normal normal normal 16px/30px MontserratRegular;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-bottom: 70px;
        opacity: 1;
    }

    .two-block-image-text-right-body {
        max-width: 664px;
    }
}

.contrast-two-block-image-text {
    background: #FFF 0% 0% no-repeat padding-box;
    padding: 100px 100px 0px 100px;

    p {
        color: #28193A;
    }

    h2 {
        color: #12032E;
    }

}

.microsoft-block-holder-content {
    margin-bottom: 80px;

    .microsoft-block-holder-body {
        background-image: url('../assets/Media/siem-microsoft.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 200px 138px;
    }

    h2 {
        text-align: left;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 40px;
    }

    p {
        text-align: left;
        font: normal normal normal 20px/30px MontserratMedium;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 40px;
    }

    ul {
        list-style: none;
        padding: 0px;
    }

    ul li::before {
        content: '▶';
        display: inline-block;
        margin-right: 12px;
    }

    li {
        text-align: left;
        font: normal normal normal 16px/30px MontserratMedium;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 20px;
    }
}
