.blog-block-content-holder {
    padding-bottom: 40px;

    .blog-block-body {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 60px;
    }

    .blog-block-holder {
        width: calc(50% - 20px);
        padding: 70px 90px;
    }

    .blog-block-left-holder {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;

        h2::before {
            background: transparent linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0% no-repeat
                padding-box;
            opacity: 1;
            content: "";
            height: 68px;
            width: 18px;
            position: absolute;
            top: 0px;
            left: -5px;
            z-index: -1;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
        }
    }

    .blog-block-right-holder {
        background-image: url(../assets/Media/images-block-2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        h2::before {
            background: transparent linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0% no-repeat
                padding-box;
            opacity: 1;
            content: "";
            height: 68px;
            width: 18px;
            position: absolute;
            top: 0px;
            left: -5px;
            z-index: -1;
        }
    }

    p {
        font: normal normal bold 16px/20px MontserratBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 60px;
        z-index: 1;
        position: relative;
    }

    h2 {
        text-align: left;
        height: 185px;
        font: normal normal bold 43px/43px RalewayBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.blog-detail-container {
    margin-top: 60px !important;
    margin-bottom: 60px;
}

.blog-detail-container h2 {
    font: normal normal normal 38px/45px MontserratMedium;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 20px;
    margin-top: 30px;
}
.blog-detail-container h1 {
    text-align: -webkit-left;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 10px;
}
.time-date-blog {
    margin-bottom: 25px;
    font: normal normal normal 21px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #281d42;
}
.blog-detail-container .subtitle-blog-detail-item {
    margin-bottom: 30px;
}

.blog-detail-container p {
    font: normal normal bold 16px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #28193a;
}
.blog-detail-container img {
    width: 100%;
    margin-bottom: 25px;
}
