.service-info-holder {
    padding: 193px 310px 35px 130px !important;

    h3 {
        text-align: left;
        font: normal normal normal 25px/40px MontserratMedium;
        letter-spacing: 0px;
        color: #0a0a50;

        b {
            font-family: "MontserratBold";
        }
    }
}

.service-our-service-title-holder {
    padding-top: 100px;
    padding-bottom: 120px;

    p {
        text-align: center;
        font: normal normal bold 16px/30px MontserratBold;
        letter-spacing: 0px;
        color: #28193a;
        opacity: 1;
        max-width: 812px;
        margin: 0 auto;
    }

    h3 {
        /* UI Properties */
        text-align: center;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #12032e;
        opacity: 1;
        margin-bottom: 75px;
    }
}

.container-serive-image-text-block {
    padding: 170px 260px 100px 140px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    h1 {
        text-align: left;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 80px;
        max-width: 700px;
    }

    p {
        text-align: left;
        font: normal normal normal 16px/30px MontserratMedium;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        max-width: 812px;
    }
}

.service-our-service-item-holder-content {
    .content-our-service-content-holder {
        background: #f2f0ed 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 74px 170px 113px 140px;

        p {
            text-align: left;
            font: normal normal normal 16px/30px MontserratRegular;
            letter-spacing: 0px;
            color: #0a0a50;
            opacity: 1;
        }
    }

    .service-our-service-item {
        margin-bottom: 80px;
    }

    .content-our-service-content-holder-soc {
        display: flex;
        justify-content: space-between;
    }

    .content-our-service-content-holder-soc-right {
        width: 500px;
        margin-top: 100px;
        position: relative;
    }

    .content-our-service-content-holder-soc-left {
        width: 700px;
    }

    .content-our-service-content-holder h2 {
        text-align: left;
        font: normal normal normal 57px/61px RalewayRegular;
        letter-spacing: 0px;
        color: #281d42;
        opacity: 1;
        margin-bottom: 100px;
    }

    .penetration-block-items-list {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .penetration-block-list-item {
        flex: 1;
        text-align: center;
        padding: 0px 31px;
    }

    .penetration-item-title {
        text-align: center;
        font: normal normal bold 57px/61px RalewayBold;
        letter-spacing: 0px;
        color: #281d42;
        opacity: 1;
        margin-top: 50px;
    }

    .penetration-item-desc {
        text-align: center;
        font: normal normal normal 16px/30px MontserratRegular;
        letter-spacing: 0px;
        color: #281d42;
        opacity: 1;
        margin-top: 30px;
    }

    .guardian-holder-content {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
    }

    .guardian-holder-content-left {
        width: 580px;
    }

    .guardian-holder-content-right {
        text-align: right;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 550px;
        height: 550px;
    }

    .guardian-holder-content-left ul li {
        text-align: left;
        font: normal normal normal 16px/30px MontserratRegular;
        letter-spacing: 0px;
        color: #281d42;
        opacity: 1;
        margin-right: 135px;
    }
}
.guardian-text-item {
    position: absolute;
    font: normal normal bold 14px/18px MontserratMedium;
    letter-spacing: 0px;
    color: #12032e;
    text-align: center;
    border: 2px solid #db9e7c;
    height: 135px;
    width: 135px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guardian-item-1-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font: normal normal bold 14px/18px MontserratMedium;
    color: #fff;
    font-size: 46px;
    background: #12032f;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.guardian-item-2-text.guardian-text-item {
    top: 5%;
    left: 57%;
}
.guardian-item-3-text.guardian-text-item {
    top: 50%;
    left: 85%;
    transform: translate(-50%, -50%);
}
.guardian-item-4-text.guardian-text-item {
    top: 70%;
    left: 57%;
}
.guardian-item-5-text.guardian-text-item {
    top: 70%;
    left: 22%;
}
.guardian-item-6-text.guardian-text-item {
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
}
.guardian-item-7-text.guardian-text-item {
    top: 5%;
    left: 20%;
    padding: 0px 10px;
}
.guardian-holder-content-left .active {
    font-weight: bold !important;
}
