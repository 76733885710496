@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@font-face {
    font-family: MontserratMedium;
    src: url(../../assets/fonts/montserrat/Montserrat-Medium.ttf);
}
.main-content-admin {
    background-color: #f3f3f9 !important;
    font-size: 15px;
    font-family: MontserratMedium !important;
    height: 100vh;

    .container-fluid {
        width: 100% !important;
    }
}

.form-control {
    font-size: 0.8125rem !important;
}

.sidebar-main-content {
    width: 250px;
    z-index: 1002;
    // background: #001C30;
    border-right: 1px solid #001c30;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    padding: 0 0 calc(70px + 25px) 0;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    background: linear-gradient(0deg, rgba(2, 2, 2, 1) 0%, rgba(51, 51, 51, 1) 100%);
}

.header-main-content {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: #fff;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    border-bottom: 1px solid #e9ebec;
    left: 251px;
}

.main-content-conteiner {
    margin-left: 250px;
}

.page-content {
    padding: calc(70px + 1.5rem) calc(1.5rem * 0.5) 60px calc(1.5rem * 0.5);
}

.navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    height: 70px;
    padding: 0 1.5rem 0 calc(1.5rem * 0.5);
}

.card {
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border: none !important;

    .card-header {
        padding: 1rem 1rem;
        margin-bottom: 0;
        background-color: #fff;
        border-bottom: 1px solid #e9ebec;
    }

    .card-title {
        font-size: 16px;
        margin: 0 0 0px 0;
        padding: 0px;
    }

    .card-footer {
        padding: 1rem 1rem;
        margin-bottom: 0;
        background-color: #fff;
        border-top: 1px solid #e9ebec;
        text-align: right;
    }

    .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem 1rem;
    }
}

.navbar-nav .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 13px 1.5rem;
    color: #fff;
    font-size: 13px;
    justify-content: space-between;
    position: relative;
}

.nav-link span {
    font-size: 13px;
    flex: 1;
    margin-left: 15px;
}

.navbar-brand-box {
    padding: 0 1.3rem;
    text-align: center;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.navbar-brand-box .logo {
    line-height: 70px;
    padding-top: 10px;
}
.nav-link svg {
    height: 18px;
}

.navbar-brand-box img {
    max-width: 75%;
}

.menu-dropdown .nav-sm {
    // padding-left: 1.75rem;
}
.menu-dropdown .nav-item {
    padding-left: 30px;
}

.collapse:not(.show) {
    display: block !important;
}

.menu-dropdown .nav-link:before {
    content: "";
    width: 6px;
    height: 1.5px;
    background-color: #abb9e8;
    position: absolute;
    left: 2px;
    top: 21px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    opacity: 0.5;
}
.navbar-nav {
    margin-top: 30px;
}

.account-settings-item {
    /* background: #f3f3f9; */
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: 0px 15px;
    height: 100%;
    align-items: center;
}

span.account-settings-fullname {
    color: #495057;
    font-weight: 500;
    line-height: 15px;
    font-size: 13px;
}

span.account-settings-email {
    color: #878a99;
    font-size: 13px;
}

.account-settings-list-items {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.navbar-header-item-account-settings {
    cursor: pointer;
    height: 70px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f3f3f9;
}

.breadcrumb-main {
    padding: 10px 1.5rem !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-bottom: 1px solid none;
    margin: -23px -1.5rem 10px -1.5rem;
}

li.breadcrumb-item {
    font-size: 13px;
}

.breadcrumb-title {
    font-weight: 700;
    font-size: 15px !important;
    text-transform: uppercase;
}

.menu-title {
    letter-spacing: 0.05em;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: #838fb9;
    font-weight: 600;
}

.menu-title span {
    padding: 12px 20px;
    display: inline-block;
}

.footer {
    bottom: 0;
    padding: 20px 0.75rem;
    position: fixed;
    right: 0;
    color: #98a6ad;
    height: 60px;
    background-color: #fff;
    left: 250px;
}

.auth-page-wrapper .footer {
    left: 0;
    background-color: transparent;
    color: #fff;
}

.auth-one-bg {
    // background-image: url(https://themesbrand.com/velzon/html/default/assets/images/auth-one-bg.jpg);
    background-position: center;
    background-size: cover;
}

.auth-one-bg-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 380px;
}

.auth-one-bg .bg-overlay {
    // background: -webkit-gradient(linear, left top, right top, from(#93BFCF), to(#6096B4));
    // background: linear-gradient(to right, #93BFCF, #6096B4);
    opacity: 1;
    background: linear-gradient(60deg, rgba(2, 2, 2, 1) 0%, rgba(51, 51, 51, 1) 83%);
}

.bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-color: #000;
}

.auth-page-wrapper .auth-page-content {
    padding-bottom: 60px;
    position: relative;
    z-index: 2;
    width: 100%;
}

.shape {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.shape > svg {
    width: 100%;
    height: auto;
    fill: #fff;
}

.text-primary.title-login-main-welcome {
    font-size: 1.015625rem;
    color: #495057 !important;
}

.auth-page-content .text-muted {
    --vz-text-opacity: 1;
    color: #878a99 !important;
    font-size: 13px;
}

.loader-main-content.true {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #dafffb70;
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ck-content {
    height: 400px;
}
.blog-detail-container,
.blog-detail-container p {
    font: normal normal normal 16px/30px MontserratRegular !important;
}

.edit-button-aggrid {
    min-width: 0px !important;
    padding: 2px 6px !important;
}
.edit-button-aggrid-delete-button {
    margin-left: 5px !important;
}

.inseted-document-link {
    margin-bottom: 20px;
}
.inseted-document-link div {
    cursor: pointer;
    text-decoration: underline;
}
.MuiInputBase-root {
    font-family: MontserratMedium !important;
}

.MuiFormHelperText-root {
    position: absolute !important;
    bottom: -20px !important;
}
.MuiFormControl-root {
    margin-bottom: 1.8rem !important;
}
.info-box-required-inputs {
    margin-bottom: 15px;
    color: #adadad;
    font-size: 14px;
}
.link-from-table {
    cursor: pointer;
}
.link-from-table:hover {
    text-decoration: underline;
}
.css-k4qjio-MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.6);
    font-family: MontserratMedium !important;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: #d32f2f;
}
.MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.6) !important;
    font-family: MontserratMedium !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.66 !important;
    letter-spacing: 0.03333em !important;
    text-align: left !important;
    margin-top: 4px !important;
    margin-right: 14px !important;
    margin-bottom: 0 !important;
    margin-left: 14px !important;
    color: #d32f2f !important;
}
.line-buttons-action-popup {
    margin-bottom: 20px;
}

.modal-body {
    max-height: 530px;
    overflow-y: none;
}
.body-content-holder {
    max-height: 450px;
    overflow-y: auto;
}
.modal-1 {
    z-index: 1050 !important;
}
.modal-2 {
    z-index: 1060 !important;
}
.modal-3 {
    z-index: 1070 !important;
}
.modal-3 {
    z-index: 1080 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #2e7d32 !important;
}

.preview-image-holder-admin img {
    max-width: 100px;
}
.preview-image-holder-admin {
    margin-top: 20px;
}
.image-preview-block-table {
    height: 30px !important;
}
.image-preview-block-table-detail {
    height: 150px !important;
    margin-top: 20px;
}
.main-content-admin {
    .nav-item-main:hover .nav-link-main {
        cursor: pointer;
        background-color: #ffffff38;
        color: #fff;
        transition: background-color 0.3s;
    }

    .nav-link:hover {
        color: #fff !important;
    }

    .nav-link {
        color: #fff !important;
    }
    #sidebarDashboards {
        background: #6f6f6f2e;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }
    .collapse.show {
        max-height: 200px !important;
    }

    .custom-btn-aggrid-customization .MuiButton-startIcon {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .custom-btn-aggrid-customization .add-new-lanauge-news {
        margin-right: 10px !important;
    }

    .MuiFormLabel-root {
        font-family: MontserratMedium !important;
    }

    .MuiInputBase-input {
        font-family: MontserratMedium !important;
    }

    .MuiButtonBase-root {
        font-family: MontserratMedium !important;
    }

    .custom-btn-aggrid-customization .MuiButtonBase-root {
        padding: 4px 4px !important;
    }
    .custom-btn-aggrid-customization {
        color: #505050;
    }
    .custom-btn-aggrid-customization:hover {
        cursor: pointer;
    }
    .button-lanuage-form-list {
        color: #fff !important;
    }
}

.dashboard-content-holder {
    h4 {
        font-family: MontserratMedium !important;
        padding: 0px;
        font-size: 16px;
    }
    p {
        color: #878a99 !important;
        font-size: 14px;
    }
    a {
        font-size: 13px;
        color: #171717;
    }
    .counter-value {
        font-size: 22px !important;
    }
}

.auth-page-content {
    .MuiFormLabel-root,
    h5,
    p,
    button {
        font-family: MontserratMedium !important;
    }
}
.icon-show-hide-password-input {
    position: absolute;
    right: 13px;
    top: 16px;
    color: #505050;
    cursor: pointer;
}
.col-left-dashboard {
    max-width: 300px !important;
    background: #fff;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    height: calc(100vh - 180px);
}
.avatar-sm {
    height: 3rem;
    width: 3rem;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #695eef;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

/* styles.css */
.sortable-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f3f3f3 !important;
    padding: 10px 0px;
    height: calc(100vh - 380px);
    overflow-y: auto;
    margin-top: 0px;
}

.card-body .sortable-item {
    background-color: #fff !important;
    border: 1px dashed #ddd;
    padding: 20px 10px;
    cursor: grab;
    user-select: none;
    transition: background-color 0.2s;
    margin: 0px 10px;
}

.sortable-item:hover {
    background-color: #e0e0e0;
}

.sortable-item:active {
    cursor: grabbing;
}
